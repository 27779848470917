import { Link } from "gatsby";
import React from "react";

import Logo from "../../assets/img/logo.svg";

export interface Props {
  spacerClassName?: string;
}

export const NavHeader = ({spacerClassName}: Props) => (
  <>
    <nav
      className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
      id="mainNav"
    >
      <div className="container px-5">
        <Link className="navbar-brand fw-bold" to="/">
          <img src={Logo} className="header-logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
          <i className="bi-list"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
            <li className="nav-item">
              <a className="nav-link me-lg-3" href="#features">
                Features
              </a>
            </li>

            <li className="nav-item">
              <Link className="nav-link me-lg-3" to="/blog/">
                Blog
              </Link>
            </li>
          </ul>
          <a
            className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0"
            href="mailto:tim@mandarinput.com"
          >
            <span className="d-flex align-items-center">
              <i className="bi-chat-text-fill me-2"></i>
              <span className="small">Contact</span>
            </span>
          </a>
        </div>
      </div>
    </nav>
    <div className={"nav-spacer " + spacerClassName ?? ""} />
  </>
);

export default NavHeader;
